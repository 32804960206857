<template>
  <b-modal
    id="modal-delete-development-role"
    title="Delete Development Role"
    hide-header
    hide-footer
    centered
    size="md"
  >
    <div class="px-3 pt-5 text-center">
      <img src="@/assets/close_up.png" alt="" width="120" height="120" />
      <h4 class="text-danger text-bold red mt-4">Delete Development Role</h4>
      <p class="text-muted mb-5">Are you sure want to delete this data ?</p>
    </div>
    <b-row class="mb-1">
      <b-col>
        <b-button class="reset" variant="outline-warning" block @click="cancel">
          No, Cancel
        </b-button>
      </b-col>
      <b-col>
        <b-button class="next" variant="warning" block @click="deleteData">
          Delete
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  methods: {
    cancel() {
      this.$bvModal.hide('modal-delete-development-role')
    },
    deleteData() {
      this.$emit('delete')
      this.cancel()
    }
  }
}
</script>
