<template>
  <b-modal
    id="modal-development-role"
    :title="isEdit ? 'Edit Role' : 'New Role'"
    hide-footer
    centered
    size="md"
  >
    <validation-observer ref="form" slim>
      <validation-provider
        name="Role Code"
        rules="required|min:2|max:10"
        v-slot="{ errors }"
      >
        <b-form-group label="Role Code *" :invalid-feedback="errors[0]">
          <b-form-input
            placeholder="Enter role code"
            v-model="role.code"
            trim
            :class="{ 'is-invalid': errors.length }"
          />
        </b-form-group>
      </validation-provider>

      <validation-provider
        name="Role Name"
        rules="required|min:2|max:100"
        v-slot="{ errors }"
      >
        <b-form-group label="Role Name *" :invalid-feedback="errors[0]">
          <b-form-input
            placeholder="Enter role name"
            v-model="role.name"
            trim
            :class="{ 'is-invalid': errors.length }"
          />
        </b-form-group>
      </validation-provider>
      <b-button class="next" variant="warning" block @click="submitRole">
        Submit
      </b-button>
    </validation-observer>
  </b-modal>
</template>

<script>
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: () => false
    },
    role: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: this.role
    }
  },
  methods: {
    async submitRole() {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return false
      } else {
        if (!this.isEdit) {
          this.role.leader = false
          this.role.level = ['']
        }
        const form = { ...this.role }
        this.$emit('submit', form)
      }
    },
    cancel() {
      this.$bvModal.hide('modal-development-role')
    }
  }
}
</script>

<style lang="scss" scoped>
.total {
  color: #2f80ed;
}

.remaining {
  color: #f7ac26;
}
</style>
