<template>
  <div>
    <b-card class="mt-3" body-class="py-4 px-3">
      <div class="d-flex justify-content-between">
        <b-button class="next" variant="warning" @click="openModal">
          Add Role
        </b-button>
        <b-form-group class="m-0 form">
          <b-input-group>
            <b-form-input
              v-model="keyword"
              placeholder="Search role code or role name "
              @change="$refs.table.refresh()"
            />
          </b-input-group>
        </b-form-group>
      </div>
      <br />
      <b-row v-show="!items.length">
        <b-col>
          <div align="center">
            <img src="@/assets/icon-no-invoice.png" />
          </div>
        </b-col>
      </b-row>
      <b-table
        ref="table"
        v-show="items.length"
        :items="fetchDevelopmentRole"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        striped
        responsive
      >
        <template #cell(no)="{ index }">
          {{ index + 1 }}
        </template>

        <template #cell(roleCode)="{ item }">
          {{ item.code }}
        </template>

        <template #cell(roleName)="{ item }">
          {{ item.name }}
        </template>

        <template #cell(action)="{ item }">
          <b-link class="mr-2" @click="goToEdit(item)">
            <fa-icon class="text-muted" icon="edit" />
          </b-link>
          <b-link @click="goToDelete(item.id)">
            <fa-icon class="text-muted" icon="trash" />
          </b-link>
        </template>
      </b-table>
      <div class="d-flex justify-content-between">
        <div>
          <span>Show</span>
          <b-form-select
            v-model="perPage"
            :options="pageOptions"
            @change="$refs.table.refresh()"
            class="page-option ml-2 mr-2 w-auto"
            size="sm"
          />
          <span>Entries</span>
        </div>
        <div>
          <b-pagination
            class="mb-0"
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            pills
            hide-goto-end-buttons
            @input="$refs.table.refresh()"
          />
          <span class="show-entries mr-auto">
            {{
              `Show ${
                totalRows === 0 ? 0 : (currentPage - 1) * perPage + 1
              } to ${
                totalRows > currentPage * perPage
                  ? currentPage * perPage
                  : totalRows
              } of ${totalRows} entries`
            }}
          </span>
        </div>
      </div>
    </b-card>
    <modal-development-role
      :isEdit="isEdit"
      :role="isEdit ? roleData : {}"
      @submit="(role) => submitRole(role)"
    />
    <modal-delete-development-role @delete="deleteRole" />
  </div>
</template>

<script>
import api from '@/api'
import ModalDevelopmentRole from './ModalDevelopmentRole.vue'
import ModalDeleteDevelopmentRole from './ModalDeleteDevelopmentRole.vue'
export default {
  components: { ModalDevelopmentRole, ModalDeleteDevelopmentRole },
  data: () => ({
    keyword: '',
    sortBy: '',
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
    totalPage: 0,
    fields: [
      {
        key: 'no',
        label: 'No',
        tdClass: 'text-center',
        thClass: 'text-center'
      },
      {
        key: 'roleCode',
        label: 'Role Code',
        tdClass: 'text-center',
        thClass: 'text-center',
        sortable: false
      },
      {
        key: 'roleName',
        label: 'Role Name',
        tdClass: 'text-center',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'action',
        label: 'Action',
        tdClass: 'text-center',
        thClass: 'text-center'
      }
    ],
    items: [],
    pageOptions: [10, 20, 50, 100],
    candidateIdDeleted: null,
    isEdit: false,
    idRole: '',
    roleData: {
      name: null,
      code: null,
      leader: false,
      level: ['']
    }
  }),
  methods: {
    async fetchDevelopmentRole() {
      const { data } = await api.developmentRole.getDevelopmentRole({
        page: this.currentPage,
        pageSize: this.perPage,
        filters: this.keyword ? `(code|name)@=*${this.keyword}` : '',
        sorts: this.sortBy
      })
      this.items = data.data.length !== 0 ? data.data.data : []
      this.totalRows = data.data.length !== 0 ? data.data.totalData : 0
      this.totalPage = data.data.totalPage

      this.items.map((v, i) => {
        if (i % 2 === 0) {
          v._rowVariant = 'warning'
        }
      })
      return this.items
    },
    openModal() {
      this.isEdit = false
      this.$bvModal.show('modal-development-role')
    },
    goToEdit(data) {
      this.roleData = { ...data }
      this.isEdit = true
      this.$bvModal.show('modal-development-role')
    },
    goToDelete(id) {
      this.candidateIdDeleted = id
      this.$bvModal.show('modal-delete-development-role')
    },
    async deleteRole() {
      try {
        await api.developmentRole.deleteRole(this.candidateIdDeleted)
        this.$bvToast.toast('Development role succesfully deleted.', {
          headerClass: 'd-none',
          solid: true,
          variant: 'success'
        })
        this.$refs.table.refresh()
        this.candidateIdDeleted = null
      } catch (error) {
        this.$nextTick(() => {
          this.$bvToast.toast(
            error.message ? error.message.messageEng : error.data.messageEng,
            {
              headerClass: 'd-none',
              solid: true,
              variant: 'danger'
            }
          )
        })
      }
    },
    async submitRole(role) {
      let message = ''
      try {
        if (this.isEdit) {
          await api.developmentRole.editRole(role.id, role)
          message = 'Data has been updated.'
        } else {
          await api.developmentRole.addRole(role)
          message = 'Data has been saved successfully.'
        }
        this.$bvToast.toast(message, {
          headerClass: 'd-none',
          solid: true,
          variant: 'success'
        })
        this.$bvModal.hide('modal-development-role')
        this.keyword = ''
        this.$refs.table.refresh()
        this.isEdit = false
        this.roleData = null
      } catch (error) {
        this.$nextTick(() => {
          this.$bvToast.toast(
            error.message ? error.message.messageEng : error.data.messageEng,
            {
              headerClass: 'd-none',
              solid: true,
              variant: 'danger'
            }
          )
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.col,
.col-2,
.col-3,
.col-6 {
  padding: 0 16px;
}

.form {
  width: 200px;
}
</style>
